<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col
        cols="12"
        :style="isMobile()?'':'font-size:20px'"
      >
        <h1>
          If you need to contact support,
          <br>please remember to
          <font :color="this.$store.state.darkTheme?lblue:lblue">
            give your perseus login
          </font> in your message.
        </h1>
      </v-col>

      <v-col
        cols="12"
        :style="isMobile()?'':'font-size:25px'"
      >
        <v-divider
          :dark="this.$store.state.darkTheme ? true : false"
          class="my-4 mx-12"
        />
        <v-alert
          type="info"
          :color="this.$store.state.darkTheme?'primary':'gmiddleblue'"
          text
          :style="isMobile()?'':'font-size:25px'"
        >
          <h3>
            Please try to provide the following informations, <br v-if="!isMobile()">
            they will allow to speed up and improve the help we can give you.
          </h3>
          <h3 class="font-weight-light my-4">
            <div> When did the problem occur? </div>
            <div> What cluster is concerned? </div>
            <div> What operation is impacted (Job and Job ID)? </div>
            <div> What was the error message? </div>
          </h3>
        </v-alert>
        <v-divider
          :dark="this.$store.state.darkTheme ? true : false"
          class="my-4 mx-12"
        />
      </v-col>
      <!-- Contact by mail -->
      <v-col>
        <v-icon
          x-large
          :color="this.$store.state.darkTheme?lblue:dblue"
          dark
        >
          fas fa-envelope
        </v-icon>
        <h1>
          Contact by email
        </h1>
        <v-btn
          href="mailto:sos-gricad@univ-grenoble-alpes.fr"
          style="text-transform:none"
          :large="isMobile()?true:false"
          :x-large="isMobile()?false:true"
          color="gred"
        >
          sos-gricad@univ-grenoble-alpes.fr
        </v-btn>
      </v-col>
      <!-- Contact by opening a ticket -->
      <v-col>
        <v-icon
          x-large
          :color="this.$store.state.darkTheme?lblue:dblue"
          dark
        >
          fas fa-lock
        </v-icon>
        <h1>
          Open a ticket
        </h1>
        <v-btn
          href="http://sos-gricad.univ-grenoble-alpes.fr"
          style="text-transform:none"
          :large="isMobile()?true:false"
          :x-large="isMobile()?false:true"
          color="gred"
        >
          sos-gricad.univ-grenoble-alpes.fr
        </v-btn>
      </v-col>
    </v-row>
    <br><br><br>
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data () {
      return {
        red: config.colors.g_red,
        dblue: config.colors.g_darkblue,
        lblue: config.colors.g_lightblue,
      }
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>

<style>
</style>
